import React, { useState, useEffect } from 'react'
import { ThemeProvider, Global} from '@emotion/react';
import { darkMode } from './styles/_themes';
import { globalCss } from './styles/_globalStyles';
import './styles/globals.css';



import Prompts from './components/Prompt';
import SplashScreen from './components/SplashScreen';

function App() {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 5000)
  }, [])

  return (
      <ThemeProvider theme={darkMode}>
          <Global styles={globalCss}/>
          {isLoading === false ? (
            <Prompts/>
          ) : (
            <SplashScreen/>
          )}
      </ThemeProvider>
    );
}

export default App;
