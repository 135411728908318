import { useState } from "react";
import PacmanLoader from "react-spinners/PacmanLoader";
import {isMobile} from 'react-device-detect';

function SplashScreen(){
    let [loading] = useState(true);
    let [color] = useState("#ffffff");    

    return (
        <>
        {isMobile === false ? (
            <div
                style={{
                    position: 'absolute', left: '20%', top: '25%',
                    transform: 'translate(-50%, -50%)'
                }}
                >
                <PacmanLoader color={color} loading={loading} size={200} />
            </div>
        ) : (
            <div
                style={{
                    position: 'absolute', left: '22%', top: '25%',
                    transform: 'translate(-50%, -50%)'
                }}
                >
                <PacmanLoader color={color} loading={loading} size={50} />
            </div>
        )}     
        </>
    );
}

export default SplashScreen;
