import styled from '@emotion/styled';
import { useState } from 'react';
import { browserName, osName, osVersion } from 'react-device-detect';

import { regularLightTextStyle } from '../styles/_typographies';

const ClockDiv = styled.div`
    color: ${(props) => props.theme.colors.white};
    width: 100%;
    padding-left: 10px;
    justify-content: left;
    display: flex;
    flex-direction: column;
    ${regularLightTextStyle};
`;

function Clock(){
    const [date] = useState(new Date());    
    
    return (
        <ClockDiv>
            Login terakhir: {date.toLocaleDateString('id-ID',{
                day: 'numeric',
                weekday: 'short',
                month: 'short',
            }) + ' ' + date.toLocaleTimeString()
            } dari {browserName} {osName} {osVersion}
        </ClockDiv>
    );
}

export default Clock;
